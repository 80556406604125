import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
// import CustomDataTable from '../../components/CustomDataTable'
import { fetchServicePriceListApi, workerPriceDeclinePostApi } from '../../reducers/TeamMemberSlice'
// import {fetchProfile} from '../../reducers/TeamMemberSlice'
// import {Button, Form} from 'react-bootstrap'
import Layout from '../../components/Layout'
import { useNavigate, useParams, useLocation } from "react-router-dom";
// import CustomModelComponent from "../../component/CustomModelComponent";
// import CustomModal from "../../modal/CustomModal";
// import SuccessModalComponent from "../../modal/SuccessModalComponent";
// import HeaderComponent from "../../component/provider/HeaderComponent";

// import GeneralInfo from "../../component/provider/GeneralInfo";
// import UserOrders from "../../component/provider/ProviderOrders";
// import CategoryRequested from "../../component/provider/CategoryRequested";
// import BusinessInfo from "../../component/provider/BusinessInfo";
// import SetProviderStatus from "../../component/provider/SetProviderStatus";
import LoadingComponent from "../../modal/LoadingComponent";
// import ErrorComponent from "../../modal/ErrorComponent";
import ServiceInfo from "../../component/priceManagement/ServiceInfo";
import PricePackage from "../../component/priceManagement/PricePackage";
import BundleServiceAttribute from "../../component/priceManagement/BundleServiceAttribute";
// import CardViewForServiceComponent from "../../component/items/CardViewForServiceComponent";
// import {item} from
 
import PriceDeclineModal from "../../modal/PriceDeclineModal";
// import DateIcon from "../../assets/images/date-icon.png"
import WatchIcon from "../../assets/images/watch-icon.svg"
import PriceRequestMessage from "../../component/priceManagement/PriceRequestMessage";
import { getDateString } from "../../utility/DateFormate";
import { getStatusClassName } from "../../utility/GetStatusName";
// import PriceRequestMessage from "../../priceManagement/PriceRequestMessage";
import ProdiverCardForPriceRequestComponent from "../../component/card/ProdiverCardForPriceRequestComponent";
const ServiceViewWithPriceList = () => {
    const [status, setStatus] = useState(false)
    const [requesDate, setRequesDate] = useState(false)
    const [rest, setReset] = useState(false)

    const [franchiseTextId,  setFranchiseTextId] = useState(false)
    const { providerPrice, loading, dedeclineMessage} = useSelector((state) => state.teamMembers)
    const dispatch = useDispatch()
    const {item, serviceId} = useParams()
    const navigate = useNavigate()
    const location = useLocation()
    const [activeTab, setActiveTab] = useState('')
    const handleClick = (e) => setActiveTab(e);
    const [zone, setZone] = useState([])
    const handleCheckbox = (item) => {
        if (zone.includes(item)) {
            // Remove the item if it exists
            const updatedZone = zone.filter(currentItem => currentItem !== item);
            setZone(updatedZone);
        } else {
            // Add the item if it doesn't exist
            const updatedZone = [...zone, item];
            setZone(updatedZone);
        }        
    };
  

    // const handleNext = (e) => setActiveTab('category');
    // const handleNextInfo = (e) => setActiveTab('info');

    const [priceDeclineModal, setPriceDeclineModal] = useState(false);
 
    const priceDeclineModalClose = (data) => {setPriceDeclineModal(data);}
    const resetStatus = (data) => setReset(data)
    const searchParams = new URLSearchParams(location.search);
    const categoryTextId = searchParams.get('ca');  

    // console.log(providerPrice)
    useEffect(() => {
        // dispatch(fetchProfile(item))
        const res = dispatch(fetchServicePriceListApi({workerTextId:item, serviceTextId: serviceId, categoryTextId:categoryTextId, "planTextId":activeTab}))
        res.then((results) => { 
            if(!results.error) {
            document.title = 'Provider Price';
            setActiveTab(results.payload.results.plan_list[0].textId)
            setFranchiseTextId(results.payload.results.franchiseTextId)
            setRequesDate(results.payload.results.requested_date)
            // setMessage(results.payload.results.rejected_contents)
       
            if(results.payload.results.new_price_status){
                setStatus(results.payload.results.new_price_status)
            } else{
                setStatus(results.payload.results.pricingStatus)
            }
            }
        })
        res.catch((error) => {
            console.error('Promise rejected:', error);
        });
    }, [serviceId, categoryTextId, item, rest, dispatch])

    const declineModalData={workerTextId:item, franchiseTextId:franchiseTextId,serviceTextId: serviceId, categoryTextId:categoryTextId, adminTextId:localStorage.getItem('textId'),priceRejectedNote:dedeclineMessage.priceRejectedNote,rejectedServiceId: dedeclineMessage.rejectedServiceId};
     
    return (
        <Layout 
            page_title={'Price Request Details'} 
            breadcrumb={{' Provider Request':'/provider/manage-price', ' Price Request  ':''}} 

            component={
                    <>
                    {/* {dedeclineMessage && <>{dedeclineMessage}</>} */}
                    
                    {dedeclineMessage && <PriceRequestMessage data={dedeclineMessage} handleClick={priceDeclineModalClose}/>}
                    <div className='section-two-column price-request'>
                        <div className='left-block w100-200'>

                            
                            
                            {/* <HeaderComponent profile={profile}/> */}

                            <div className="help-price-page container tabs-overview rounded-12">
                                {providerPrice.service_info && <ServiceInfo data={providerPrice.service_info}/>}

                                 {providerPrice && providerPrice.service_info.pricingBy === 'Bundle' && <BundleServiceAttribute data={providerPrice.plan_list[0].attributes} />}
                                 <hr className="divider"/>

                                {/* {providerPrice.service_info && providerPrice.service_info.pricingBy !== 'Bundle' ? <> */}

                                <div className="container tabs-overview">
                                        
                                        {providerPrice.service_info && providerPrice.service_info.pricingBy !== 'Bundle' && <>
                                        <div className="text-black text-xl font-bold font-['Inter']">Service Level </div>
                                        <div className="tab-section d-flex justify-content-between align-items-center w-100">
                                            <div className="tab-box">
                                                {providerPrice && providerPrice.plan_list.map((plan, index) => (
                                                    <button key={index} className={activeTab === plan.textId ? 'tab-btn active' : 'tab-btn'}
                                                    onClick={() => handleClick(plan.textId)}>{plan.title}
                                                    </button>
                                                ))}
                                            </div>
                                            

                                        </div> 
                                        </>}
                                         
                                        <div className={`content-box w-100 ${providerPrice.service_info && providerPrice.service_info.pricingBy !== 'Bundle' && 'mt-4'}`}>
                                            {providerPrice && providerPrice.plan_list.map((plan, index) => (<>
                                                {providerPrice.organized_data[plan.textId] && activeTab===providerPrice.organized_data[plan.textId].planTextId && 
                                                <>
                                                    {providerPrice.service_info && providerPrice.service_info.pricingBy !== 'Bundle' && <><div className="w-auto relative">

                                                        <strong className="text-black text-base font-medium font-['Inter']">Details:</strong>
                                                        <div className=" text-black text-[14px] font-medium font-['Inter'] leading-[25px]">
                                                            {providerPrice.organized_data[plan.textId].plan_details} 
                                                        </div>
                                                    </div>
                                                    <hr className="divider"/>
                                                    </>
                                                    }
                                
                                                    <div className="service-areas">
                                                        <div className="mb-3 strong text-xl">Service Areas</div>

                                                        <div className="areas">
                                                            <div className="accordion" id="accordionPanelsStayOpenExample">
                                                                {providerPrice.organized_data[plan.textId] && providerPrice.organized_data[plan.textId].zoneArray.map((each, index) => (
                                                                    <PricePackage index={index} status={status}
                                                                    serviceType={providerPrice.service_info.pricingBy} 
                                                                    title={providerPrice.service_info.title} 
                                                                    oldData={providerPrice.isOldPriceConfiguration?.organized_data[plan.textId]?.zoneArray[index]||false} 
                                                                    each={each} handleCheckbox={handleCheckbox}  /> 
                                                                    )
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                     
                                                    {zone.length > 0 &&
                                                    <div className="w-[90px] h-[37px] mr-2  bg-red-500 rounded-md border-2 border-gray-200 justify-center items-center gap-2.5 inline-flex  hover:bg-[#ff0000]">
                                                                <div onClick={()=>setPriceDeclineModal(true)} className="px-5 py-2.5 text-sm font-medium font-['Inter'] cursor-pointer  text-[#fff]">Recected</div>
                                                            </div>}
                                                        
                                                            {status!=='Approved' &&<div
                                                                className="w-[90px] h-[37px] bg-[#4DAC85] rounded-md border-2 border-gray-200 justify-center items-center gap-2.5 inline-flex hover:bg-[#008951]">
                                                                <div className=" px-5 py-2.5 text-[#fff] text-sm font-medium font-['Inter'] cursor-pointer" 
                                                                onClick={()=>{ 
                                                                    const res = dispatch(workerPriceDeclinePostApi({...declineModalData, reviewStatus:'Approve',"planTextId":activeTab}));
                                                                    res.then((results) => {
                                                                        if(!results.error){
                                                                            navigate(-1)
                                                                        }
                                                                    })
                                                                    res.catch((error) => {
                                                                        console.error('Promise rejected:', error);
                                                                    });

                                                                }}>Approve</div>
                                                            </div>}  
                                                    {/* for tab || {activeTab} */}
                                                </>}
                                            </>)
                                        )}
                                         
                                    </div>
                                </div>
                             
                                {priceDeclineModal && <PriceDeclineModal data={declineModalData} close={priceDeclineModalClose} resetStatus={resetStatus} zodeArr={zone} planTextId={activeTab}/>}
                                
                                {/* {status!=='Rejected' &&
                                <div
                                    className="w-[90px] h-[37px] mr-2  bg-red-500 rounded-md border-2 border-gray-200 justify-center items-center gap-2.5 inline-flex  hover:bg-[#ff0000]">
                                    <div onClick={()=>setPriceDeclineModal(true)} className="px-5 py-2.5 text-sm font-medium font-['Inter'] cursor-pointer  text-[#fff]">Recected</div>
                                </div>}
                            
                                {status!=='Approved' &&<div
                                    className="w-[90px] h-[37px] bg-[#4DAC85] rounded-md border-2 border-gray-200 justify-center items-center gap-2.5 inline-flex hover:bg-[#008951]">
                                    <div className=" px-5 py-2.5 text-[#fff] text-sm font-medium font-['Inter'] cursor-pointer" 
                                    onClick={()=>{ 
                                        const res = dispatch(workerPriceDeclinePostApi({...declineModalData, reviewStatus:'Approve',"planTextId":activeTab}));
                                        res.then((results) => {
                                        
                                            if(!results.error){

                                                navigate(-1)
                                            }
                                        })
                                        res.catch((error) => {
                                            console.error('Promise rejected:', error);
                                        });

                                    }}>Approve</div>
                                </div>}   */}
                            
                        </div>
                        </div>
                        <div className="right-block w100-200"> <div class="category">
                             {providerPrice && <ProdiverCardForPriceRequestComponent
                                title={'Service Provider'}
                                data={{'email':providerPrice.workerEmail,
                                'address':providerPrice.workerAddress,
                                'name':providerPrice.workerName,
                                'phone':providerPrice.workerPhone,
                                'photo':providerPrice.worker_image,
                                'services':providerPrice.worker_last_services,
                                'totalOrder':providerPrice.total_worker_order,
                            }} 
                        />}
                           </div>
                        </div>
                    </div>
                          
                        {loading && <LoadingComponent message={''}/>}
                        {/*{error && <ErrorComponent close={handleSuccess}/>}*/}
                    </>
                }
                 top_content={
                    <div className="request-status">
                         {status && <>
                         <div className="request-info">
                            <img src="/svg/Calendar.svg" alt=""/>
                            <span>Requested on  {getDateString(requesDate)} </span>
                        </div>
                        <div className={`status-badge status-${getStatusClassName(status)}`}>
                            <img src={WatchIcon} alt="Clock Icon" className="icon" />
                            <span>{status}</span>
                        </div>
                        </>}
                    </div>

                 }></Layout>
    );
}

export default ServiceViewWithPriceList;