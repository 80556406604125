import {Form} from "react-bootstrap";
import uploadImgIcon from "../../../assets/images/upload-img-icon.svg";
// import logo from "../../../assets/images/logo.png";
// /assets/images/category-upload-img.svg
 
import {BASE_URL} from "../../../BaseUrl";

import {useDispatch, useSelector} from 'react-redux'
import React, {useEffect, useState} from "react";
 
import {useFormik} from "formik";
import { updateIdentity, createNewIdentity, 
    // successConfirmation

 } from '../../../reducers/IdentitySlice'
import {useNavigate} from "react-router-dom";
import LoadingComponent from "../../../modal/LoadingComponent";
// import SuccessModalComponent from "../../../modal/SuccessModalComponent";
import SuccessComponent from "../../../modal/SuccessComponent";
// import ErrorComponent from "../../../modal/ErrorComponent";
 
// import {breadcrumbTree} from "../../../reducers/LocalDataSlice";

const AddNewIdentityComponent = ({data, close}) => {
    // const [loading, setLoading] = useState(false);
    // const [thumbIcon, setIcon] = useState(false);
    const [thumbImage, setImage] = useState(false);
    // const [imageError, setImageError] = useState(false);
    // const [itemAddStatus, setItemAddStatus] = useState(false);
    const {loading} = useSelector((state) => state.category)
    const dispatch = useDispatch();
    // const [editorData, setEditorData] = useState('');
    const [successModal, setSuccessModal] = useState(false);
    // const [isChecked, setIsChecked] = useState(false);
    const navigate = useNavigate();
    // const {item} = useParams();
    // const handleLoading = () => setLoading(loading ? false : true);
    // const handleClose = () => setClose(close ? false : true);
    console.log(data)

    // const handleSuccess = ()=>dispatch(successConfirmation());

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        const imageType = /^image\//;
        if (imageType.test(file.type)) {
            const reader = new FileReader();
            reader.onload = function (e) {
                setImage(e.target.result);
                // setValues({...values, thumbImage: e.target.result});
            };

            reader.readAsDataURL(file);
        } else {
            alert('Please select an image file.');
        }
    };
    // const handleIconChange = (event) => {
    //     const file = event.target.files[0];
    //     const imageType = /^image\//;
    //     if (imageType.test(file.type)) {
    //         const reader = new FileReader();
    //         reader.onload = function (e) {
    //             setIcon(e.target.result);
    //         };

    //         reader.readAsDataURL(file);
    //     } else {
    //         alert('Please select an image file.');
    //     }
    // };
    const openSuccessModal = (url) => setSuccessModal(url ? url : false);
    const closeSuccessModal = (url) => {
        // handleSuccess()
        console.log('successModal', successModal)
        navigate(successModal);
    };
    
    // data.index=index;
// if(data.isRegistrationProcess==="N") setIsChecked(true)

    const {values, errors, handleBlur, handleChange, handleSubmit, setValues} = useFormik({

        initialValues: data,


        onSubmit: (values, action) => { 
            const res = dispatch(data.update===true?updateIdentity(values):createNewIdentity(values));
                // const res = dispatch(updateServiceItem(values));
                res.then((results)=> {
                    if(results.error) {

                    }else{
                        openSuccessModal();
                        close()
                    }
                    console.log('onSubmit response...')
                    // dispatch(fetchServiceItem())
                    // openSuccessModal();
                    // navigate(-1)

                }).catch(error => {
                    console.error('Error:', error);
                });
       
         
            // close(true);
        },
        //  validationSchema: itemFormSchema,


    });
   
    console.table('values',values)
    // console.table(' {identityArr.lenght}',identityArr.length)
    useEffect(() => {
        setSuccessModal(false)
        // dispatch(breadcrumbTree({'Service Item List': 'service-items', 'Add New Item': 'add-item'}));
        document.title = 'Service New Item';
    }, [dispatch])
    return (
        <>
            <Form onSubmit={handleSubmit} encType="multipart/form-data" className="form-data">
                <div className="section-two-column add-service">
                    <div className="left-block w100-200">
                        

                        <div className="field-row"><label className="label">Service Identity Title {values.localData}</label>
                            <Form.Control type="text" name="title" placeholder="Service Identity Title" value={values.title}
                                          onChange={handleChange} onBlur={handleBlur}></Form.Control>
                            {errors.title && <p className="red">{errors.title}</p>}
                            {/* <Form.Control type="hidden" name="categoryTextId" value={data.categoryTextId} onChange={handleChange} onBlur={handleBlur}></Form.Control> */}
                            </div>

                            

                        <div className='checked-field'> 
                            <Form.Check type="switch" id="status" label="Status" name="status" defaultChecked={values.status === 'Y'? true:false} value={values.status} 
                        onChange={(e)=>{
                            setValues({...values,status:e.target.checked?'Y':'N'});
                            }}/>
                            {/* <Form.Check type="switch" id="status" label="Category Active/Inactive Status" name="status" defaultChecked={values.status=== 'Y'? true:false} value={values.status} onChange={handleChange}/> */}
                       <br /> </div>
                            

                       <div className="field-row">
                             
                            <div className="field-row"><label className="label">Service Identity Rank</label>
                             
                            <input name="rank" placeholder="number" type="number" className="border w-100 border-slate-700 p-2 rounded-md" value={values.rank} onChange={handleChange}></input>
                            </div>
                                    {/*<span className="label-name">Include in registration process</span></label>*/}
                        </div>
                    </div>
                    <div className="right-block w100-200">
                        <div className={`category`}>
                            <strong className="title">Service Identity Icon</strong>
                            {!thumbImage && !values.image &&<span className="info">Add icon for the Service Identity </span>}
                            
                            <div className={`img-view ${errors.image && 'border-red'}`} style={{backgroundImage: `url(${thumbImage?thumbImage:(`${BASE_URL}${values.image}`)})`}}> 
                            {/* <div className={`img-view ${errors.image?'border-red':''}`} >  */}
                            {/* <div className="img-view" style={{backgroundImage: `url(${thumbImage?thumbImage:values.image?(`${BASE_URL}${values.image}`): uploadImgIcon})`}}>  */}

                            {/* <img src={`${thumbImage?thumbImage:(`${BASE_URL}${values.image}`)}`} alt="" /> */}
                                <label id="drop-area" className="drop-image">
                                <i className="fa fa-pencil" aria-hidden="true"></i>
                                <input type="file" hidden name="image" onChange={(e) => {
                                    handleImageChange(e);
                                    setValues({...values, image: e.target.files[0],});
                                    return handleChange
                                }} accept="image/*"/>
                                </label>

                                
                                        {!thumbImage && !values.image && <><div className="canvas"><img src={uploadImgIcon} alt=""/></div>
                                        <span className="drag-drop">Drop icon here, or browse</span><br />
                                    <span className="support-image">Support: JPG, JPEG, PNG</span></>}
                                    {/* {errors.image && <p className="red">{errors.image}</p>}  */}
                            </div>
                             
                              
                       
                        </div> 
                    </div>


                    <div className="save-option">
                        <button onClick={(e)=>close()}> <span className="draft-icon"></span> Cancel</button>
                        <button className="save-btn">{data.update===true?'Update':'Save'}</button>
                    </div>

                </div>
            </Form>

            {loading && <LoadingComponent message={''}/>}
            {/* {error && <ErrorComponent close={handleSuccess}/>} */}
            {successModal && <SuccessComponent close={closeSuccessModal}/>}

        </>
    );
};

export default AddNewIdentityComponent;
