import * as Yup from "yup";

export const categorySchema = Yup.object({
  // textId: Yup.string().min(3).max(25).required("Please enter TextId"),
  title: Yup.string().max(250).required("Please enter Title"),
  details: Yup.string().min(3).max(255).required("Please enter Item Description"),
  image: Yup.string().required("Please enter Item Photo"),
});

export const notificationCategorySchema = Yup.object({ 
  title: Yup.string().min(6).max(150).required("Please enter Title"),
});

export const notificationTemplateSchema = Yup.object({ 
  title: Yup.string().min(6).max(150).required("Please enter Title"),
  notificationContent: Yup.string().min(15).required("Please enter notification content"),
  categoryTextId: Yup.string().max(150).required("Please a notification category"),
});

