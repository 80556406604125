import React, {useEffect, useRef} from 'react';
import RemarksComponent from "./RemarksComponent";
import {useLocation} from "react-router-dom";
import {BASE_URL} from "../../BaseUrl";
import ShimmerAnimation from '../../pages/shimmerAnimation/ShimmerAnimation';
import WorkerApproveComponent from './WorkerApproveComponent';
 
 
function formatDate(inputDate) {
    const dateObject = new Date(inputDate);

    // Extract day, month, and year
    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1; // Months are zero-based
    const year = dateObject.getFullYear();

    // Ensure two-digit formatting
    const formattedDay = String(day).padStart(2, '0');
    const formattedMonth = String(month).padStart(2, '0');

    // Format as "DD/MM/YYYY"
    const formattedDate = `${formattedDay}/${formattedMonth}/${year}`;
    

    return formattedDate;
}

const BusinessInfo = ({profile, tabClick, images}) => {
    const ref = useRef(0);
    const location = useLocation()
    const handleButtonClick = () => {
        // Move focus to the element
        ref.current.focus();
        // Alternatively, scroll to the element
        ref.current.scrollIntoView({behavior: 'smooth'});
    };
    const { photoIdType, photoIdNo, photoIdExpirationDate, ssn, employeeType } =profile

    console.log(images.documents2.corporation_article);

    useEffect(() => {
        // Scroll to the top when the component mounts or when the page is reloaded
        window.scrollTo(0, 0);
    }, [location]);
    return (
        <>
            {images && images.documents2 && images.documents2.selfieData &&<div className="content bg-white rounded-12 business-info " ref={ref} tabIndex={0}>

                <div className="docs">
                    <div className="mb-3 strong">Selfie <span>(As a Profile Photo)</span></div>
                    <div className="selfie-info">
                        {/* {images?<img src={`data:image/jpeg; base64, ${images.documents.selfieData}`} className="rounded"
                        alt=""/>:<ShimmerAnimation width='200px' height='200px'/>} */}
                        {images?<img src={`${BASE_URL}/${images.documents2.selfieData}`} className="rounded"
                        alt=""/>:<ShimmerAnimation width='200px' height='200px'/>}
                    </div>
                </div>
                <div className='remarks-wrapper'>
                    <RemarksComponent sectionId='selfieInfo' profile={profile} nextBtn={false} handleButtonClick={handleButtonClick}
                                      defaultStatus='Selfie Required'/>
                </div>
            </div>}

            {employeeType ==='Corporate' && <div className="content bg-white rounded-12 business-info" ref={ref} tabIndex={0}>

                <div className="corporate-tax">
                    <div className="mb-3 strong">Article of Corporation</div>
                    <div className="img-icon mb-3">
                        <i class="fa fa-university" aria-hidden="true"></i>
                        <span>State: </span>
                        <span className="text-success">{images.documents2.articleStateTitle}</span>
                    </div>
                    <div className="img-icon mb-3">
                        <i class="fa fa-address-book-o" aria-hidden="true"></i>
                        <span>Entity No: </span>
                        <span className="text-success">{images.documents2.entityNo}</span>
                    </div>
 
                    
                    {images && images.documents2.corporation_article?<img src={`${BASE_URL}/${images.documents2.corporation_article}`} className="rounded"
                                         alt=""/>:<ShimmerAnimation width='100%' height='200px'/>}
                   

    
                     
                </div>
                <div className='remarks-wrapper'>
                    <RemarksComponent sectionId='selfieInfo' profile={profile} nextBtn={false} handleButtonClick={handleButtonClick}
                                      defaultStatus='Corporate Article'/>
                </div>
            </div>}

            {employeeType ==='Corporate' && <div className="content bg-white rounded-12 business-info" ref={ref} tabIndex={0}>

                <div className="corporate-article">
                    <div className="mb-3 strong">State Tax Certificate of Corporation</div>
                    <div className="img-icon mb-3">
                        <i class="fa fa-university" aria-hidden="true"></i>
                        <span>State: </span>
                        <span className="text-success">{images.documents2.salesStateTitle}</span>
                    </div>
                    <div className="img-icon mb-3">
                        <i class="fa fa-address-book-o" aria-hidden="true"></i>
                        <span>State Tax Id: </span>
                        <span className="text-success">{images.documents2.salesStateTaxId}</span>
                    </div>
 
                    
                    {images && images.documents2.sales_tax_certificate?<img src={`${BASE_URL}/${images.documents2.sales_tax_certificate}`} className="rounded"
                                         alt=""/>:<ShimmerAnimation width='200px' height='200px'/>}
                   

    
                     
                </div>
                <div className='remarks-wrapper'>
                    <RemarksComponent sectionId='selfieInfo' profile={profile} nextBtn={false} handleButtonClick={handleButtonClick}
                                      defaultStatus='Corporate Tax Certificate'/>
                </div>
            </div>}

            {photoIdType && <div className="content bg-white rounded-12 business-info mt-4">
                <div className="about">
                    <div className="about-info">
                        <div className="mb-3 strong">Photo ID</div>
                        {photoIdType&&<div className="img-icon mb-3">
                            <img src="/svg/id-type.svg" alt=""/>
                            <span>ID Type: </span>
                            <span className="text-success">{photoIdType}</span>
                        </div>}
                        {photoIdNo &&<div className="img-icon mb-3">
                            <img src="/svg/id-type.svg" alt=""/>
                            <span>ID Number: </span>
                            <span className="text-success">{photoIdNo}</span>
                        </div>}
                        {photoIdExpirationDate&&<div className="img-icon mb-3">
                            <img src="/svg/Calendar.svg" alt=""/>
                            <span>Exp. Date: </span>  
                            <span className="text-success">{formatDate(photoIdExpirationDate)}</span> 
                        </div>}
                    </div>
                    <div className="docs">
                        <div className="docs-info mt-3 d-flex justify-content-between align-items-center">
                            <div className="front rounded-12">
                                <div className="text-center">
                                    {images && images.documents2.photoIdData1?<img src={`${BASE_URL}/${images.documents2.photoIdData1}`} className="rounded"
                                         alt=""/>:<ShimmerAnimation width='100%' height='200px'/>}
                                </div>
                            </div>
                            <div className="back rounded-12">
                                <div className="text-center">
                                    {images && images.documents2.photoIdData2?<img src={`${BASE_URL}/${images.documents2.photoIdData2}`} className="rounded"
                                         alt=""/>:<ShimmerAnimation width='100%' height='200px'/>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='remarks-wrapper'>
                    
                    <RemarksComponent sectionId='photoIdInfo' profile={profile} nextBtn={false} handleButtonClick={handleButtonClick}
                                      defaultStatus='PhotoId Required'/>
                </div>
            </div>}

            {ssn &&<div className="content bg-white rounded-12 business-info mt-4" ref={ref} tabIndex={0}>

                <div className="docs">
                    <div className="mb-3 strong">SSN <span>(Social Security Number)</span></div>
                    <div className="ssn-info">
                        <div className="img-icon">
                            {/*<img src={`data:image/jpeg; base64, ${profile.selfieData}`} alt=""/>*/}
                            <img src="/svg/ssn.svg" alt=""/>
                            <span className="text-success"> {ssn} </span>
                        </div>
                    </div>
                </div>
                <div className='remarks-wrapper'>
                    
                    <RemarksComponent sectionId='SSNInfo' profile={profile} nextBtn={true} handleButtonClick={tabClick} defaultStatus='SSN Required'/>
                </div>
            </div>}
            {employeeType==="Under Provider" && <WorkerApproveComponent profile={profile} />}
        </>
    );
}

export default BusinessInfo;