 
import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import ProviderDataTable from "./ProviderDataTable";
 
// import {filterDataTable3Col} from "../../utility/FilterDataTable";
import {filterDataTable3Col} from '../../../utility/FilterDataTable';

const ProviderListComponent = ({data}) => {
    const [query, setQuery] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [filterBy, setFilterBy] = useState('');
    const [filterByStatus, setFilterByStatus] = useState('');
    const navigate = useNavigate();

    
    let tableData1 = data.filter(item => item['status']?.toLowerCase().includes(filterByStatus.toLowerCase())) 
    let tableData = tableData1.filter(item => item['employeeType']?.toLowerCase().includes(filterBy.toLowerCase())) 
    let tableData2 = tableData.filter(item => item['email']?.toLowerCase().includes(query.toLowerCase()))
    let tableData3 = tableData.filter(item => item['workerName']?.toLowerCase().includes(query.toLowerCase()))
    let tableData4 = tableData.filter(item => item['phone']?.toLowerCase().includes(query.toLowerCase())) 

 

    // const uniqueData = tableData4;
     const uniqueData = filterDataTable3Col('email',tableData2, tableData3, tableData4);


    const columns = [
        {name: "Name", sortable: true, cell: (row) => row.workerName},
        {name: "Email", minWidth: '300px', sortable: true, cell: (row) => row.email},
        {name: "Phone", cell: (row) => row.phone},
        {name: "Company Name", cell: (row) => {return  row.companyName?row.companyName:row.employeeType}},
        {name: "Status",  minWidth: '200px', cell: (row) => <span className={`status ${row.status}`}> {row.status}</span>},
        {name: "Update",  minWidth: '120px', cell: (row) => 
         <span onClick={() => {
                            navigate(`/provider/provider-list/${row.workerTextId}` , {
                                state: {name: row.workerName}
                            })
                        }} className="btn btn-default"> Details</span>},
    ]
    // console.table(data);
    return (
        <div className='data-table-wrapper dashboard-provider'>
            {/*<h3 className='title'>Pending Service Area Request</h3>*/}
            <div className="w-[100%] flex gap-2.5 relative mb-3">
                {/*<Button variant="primary" onClick={handleShow}> Add City </Button>*/}
                <div className='search-from-data-table bg-neutral-100 rounded-lg pl-[20px]'>
                    <input name="search" placeholder={'Search by Provider Name, Email Address and, phone Number'}
                            type="text"
                            className="bg-transparent border-0 px-3 py-[10px] w-full focus:outline-none"
                            value={query ? query : ''}
                            onChange={(e) => {
                                setQuery(e.target.value)
                            }}/>
                    <div className={"absolute left-[12px] top-[12px]"}><i className="fa fa-search"
                                                                            aria-hidden="true"></i>
                    </div>
                    <div className="export-btn absolute right-0 top">
                       <Link to='/provider' className='more-details'> <span className='text'> More</span><i className="fa fa-arrow-right text-secondary"/></Link>
                    </div>
                </div>

            </div>
            
            <ProviderDataTable data={data} columns={columns}/>
        </div>
    );
}

export default ProviderListComponent;
