import {Form, Modal, Button, Image} from "react-bootstrap";
import Layout from '../../components/Layout';
import uploadImgIcon from "../../assets/images/upload-img-icon.svg";
// import logo from "../../assets/images/logo.png";
// /assets/images/category-upload-img.svg
import zone from "../../data/zone";
import {BASE_URL} from "../../BaseUrl";
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import React, {useEffect, useState} from "react";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import {useFormik} from "formik";
import {breadcrumbTree} from '../../reducers/LocalDataSlice'
import {fetchServices,  fetchServiceDetails, createService} from "../../reducers/ServiceItemSlice";
import {appendNewServices, fetchServiceWithCategory, successConfirmation} from '../../reducers/CategorySlice';
import ServicePlanAddComponent from "../../component/ServicePlanAddComponent";
import LoadingComponent from "../../modal/LoadingComponent";
import ErrorComponent from "../../modal/ErrorComponent";
import SuccessComponent from "../../modal/SuccessComponent";
import SelectServiceItemComponent from "../../component/SelectServiceItemComponent";  

import ServiceDetailsComponent from '../../component/service/ServiceDetailsComponent';

const ViewServicePage = () => {
    // const { error} = useSelector((state) => state.category)
    const {error, loading, serviceDetails} = useSelector((state) => state.serviceItem);
    // const [breadcrumb, setBreadcrumb] = useState(false);
    // const { state, location } = useLocation();
    // const [activePlan, setActivePlan] = useState();
    // const [selected, setSelected] = useState();
    const dispatch = useDispatch();
    // const [editorData, setEditorData] = useState('');
    // const [thumbIcon, setIcon] = useState(false);
    // const [thumbImage, setImage] = useState(false);

    const {service_data, itemArray, plan_list, serviceZipArray, categoryArray}=serviceDetails


    //  {serviceDetails && setSelected(serviceDetails.service_data.areaSizeUnit)}

    // let location = useLocation();
    // console.log('serviceDetails', service_data, itemArray, serviceplanArray)

   
    // const loca = location.pathname.split("/");
    // const token = localStorage.getItem("token")
    const {item, serviceId = 'root'} = useParams();
    const navigate = useNavigate();
    const [clickCount, setClickCount] = useState(1);

    const [successModal, setSuccessModal] = useState(false);
    const handleSuccess = ()=>dispatch(successConfirmation());
    const openSuccessModal = (url) => setSuccessModal(url ? url : false);
    const closeSuccessModal = (url) => {
        handleSuccess()
        // console.log('successModal', successModal)
        navigate(successModal);
    };
    console.log('item:', item)
    // console.log('service:', service)

    

 

      
    // console.log("🚀 values:", values)
// console.log(serviceDetails)

    useEffect(() => {
        // dispatch(fetchServices(state.categoryTextId))
        const res = dispatch(fetchServiceDetails(serviceId))
        document.title = 'Service Details';
        // dispatch(breadcrumbTree({
        //     'Service ': 'service',
        //     // [title]: subItem
        // }));
        res.then((results) => {
          console.log('results: ',results)
        })
      res.catch((error) => {
          console.error('Promise rejected:', error);
      });

    }, [dispatch])
    // console.table(configuration_data.service_plan)
    // console.table(serviceDetails.itemArray)

    return (
        <Layout title={'ddddd'} component={
            <>
                 <ServiceDetailsComponent  serviceTextId={serviceId} />
               
               
                {loading && <LoadingComponent message={''}/>}
                {error && <ErrorComponent close={handleSuccess} error={error}/>}
                {successModal && <SuccessComponent close={closeSuccessModal}/>}
        </>}></Layout>

    );
};

export default ViewServicePage;
