import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import AddNewIdentityComponent from "./component/AddNewIdentityComponent";
import { useDispatch, useSelector } from "react-redux";
import {BASE_URL} from "../../BaseUrl";

// import doneAll from "../../assets/images/done-all.svg";
 
 
import {fetchServiceIdentity, updateIdentity} from "../../reducers/IdentitySlice";

import CustomDataTable from "../../components/CustomDataTable";
import Layout from "../../components/Layout";
import ExportFile from "../../component/utility-component/ExportFile";
import {  toast } from 'react-toastify';
import '../../assets/tostify/ReactToastify.css';
import {sortedDataByRank} from "../../utility/FilterDataTable";
 
const IdentityListPage = () => {
  const [show, setShow] = useState(false);
 
  const [query, setQuery] = useState('');
  // const [filter, setFilter] = useState('');
  const [alertSuccess, setAlertSuccess] = useState('');
 
  const handleShow = () => setShow(true);
  const handleAlert = () => {setAlertSuccess(false)};

 

  const {data} = useSelector((state) => state.identity);
  const dispatch = useDispatch();
  // let tableData = null;
 
// console.log('data: ', data)
 
  const handleClose = (data) =>{
    // console.log(data)
    if(data) {
    if(data.update || data.id){
       toast(` Your action was successful! `);
      }  
    }
    
    setShow(false); 
    setInitialData({...initialData,update: false,
      title: null,
      textId: false,
      image: false,
      rank:0,
      status: 'Y'})
  }
  const [ initialData, setInitialData ] = useState({
      title: null,
      textId: false,
      image: false,
      rank:0,
      status: 'Y',
    })
  let uniqueData=''
   
     if (data.length > 0) {
        // const viewTableData = data.filter(item => item['title']?.toLowerCase().includes(filter.toLowerCase()))
        // let tableData1 = viewTableData.filter(item => item['question']?.toLowerCase().includes(query.toLowerCase()))
        // let tableData2 = viewTableData.filter(item => item['orderTextId']?.toLowerCase().includes(query.toLowerCase()))
        // let tableData3 = viewTableData.filter(item => item['workerName']?.toLowerCase().includes(query.toLowerCase()))
        // uniqueData = filterDataTable3Col('orderTextId', tableData1, tableData2, tableData3);
        // const sortedData = [...data].sort((a, b) => b.rank - a.rank);
        // uniqueData =  sortedDataByRank(data, 'id');
        uniqueData = data;
    }
   
  // toast.success("Login success", results); 
    useEffect(() => {
      // dispatch(fetchNotificationTemplateData());
      dispatch(fetchServiceIdentity());
      // dispatch(breadcrumbTree({'Setting': 'setting','FAQ List':''}));
      // document.title = 'HelpAbode Notification Template Create by Admin';
      
    }, [dispatch]);

  const columns = [
    { name: "Icon", sortable: true, cell: (row) => <>{row.image ? <img src={`${BASE_URL}${row.image}`} alt="Icon" class="data-table-icon identity-icon" />:<></>}</>},
    { name: "ID", sortable: true, cell: (row) => row.id },
    { name: "Title", sortable: true,  minWidth: '25%', className: 'custom-class-for-name-column', cell: (row) => row.title },
    { name: "Rank", sortable: true, cell: (row) => row.rank },
    // { name: "Status", minWidth: '100px', sortable: true, cell: (row) => row.status},
    {name: "Status", minWidth: '150px', cell: (row) =>  <select className={`form-select`} name='status' value={row.status}
    onChange={(e)=>{
      let localData = null;
      data.map((e, index)=>e.id === row.id? localData = index:''); 
      const item ={};
      item.id =  row.id;
      item.localData = localData;
      item.title= row.title;
      item.textId= row.textId
      item.rank= row.rank
      item.update= true
      item.status= e.target.value
      const res= dispatch(updateIdentity(item));
      res.then(results => {
        if(results.error) {toast(` Something went wrong. Please try again!`) }
        else {
          // toast(` Your action was successful! `)
          setAlertSuccess('Your action was successful!');
          setTimeout(handleAlert, 1000);
       
        }
      }).catch(error => {
        alert('error ---- false')
      });
      
      
    }}> 
      <option value={`Y`} > Active</option>
      <option value={`N`}  >Inactive</option> 
        
  </select> 
  

    },
    {name: "Action", width: '100px', cell: (row) => <button className="update-btn btn" onClick={() => {
          let localData = null;
           data.map((e, index)=>e.id === row.id? localData = index:'');
            setInitialData((initialData) => ({
            ...initialData,
            update: true,
            id: row.id, 
            title: row.title,
            textId: row.textId,
            image: row.image,
            rank: row.rank,
            status: row.status,
            localData: localData
          }))
          setShow(true)
        }}> <i className="fa fa-pencil" aria-hidden="true"></i> Edit</button>},

     
    // {
    //   name: "Delete", width: '100px',
    //   cell: (row) => (
    //     <Button
    //       variant="danger"
    //       onClick={() => {
    //         const approval = window.confirm("Are you sure about the deletion?");
    //         if (approval === true) {
              
    //             let localData = null;
    //             data.map((e, index)=>e.id === row.id? localData = index:''); 
    //             const item ={};
    //             item.id =  row.id;
    //             item.localData = localData;
 
    //           dispatch(deleteQuction(item));
    //         }
    //       }}
    //     >
    //       Delete
    //     </Button>
    //   ),
    // },
  ];
  return (
    <Layout page_title={'Device Identifier Category'} breadcrumb={{'Device Identifier Category':''}} 
      component={
        <> 
            {show ? <AddNewIdentityComponent data={initialData} close={handleClose} />:
            <div className='data-table-wrapper newNotification-table'>
              {alertSuccess &&<div className="alert alert-success">
                <strong>Success!</strong> {alertSuccess}
              </div>}
              {/* <ToastContainer className="ToastContainer"/> */}
               <div className="w-[100%] flex gap-2.5 relative mb-3">
                {/*<Button variant="primary" onClick={handleShow}> Add City </Button>*/}
                  {/* <Button variant="primary" onClick={handleShow}> Add Attribute </Button> */}
                  <Button className="new-configuration-btn add-new" onClick={handleShow}> Add Item </Button>

                  <div className='search-from-data-table bg-neutral-100 rounded-lg pl-[20px] relative'>
                      <input name="search" placeholder={'Search by  Title...'}
                              type="text"
                              className="bg-transparent border-0 px-3 py-[10px] w-full focus:outline-none"
                              value={query ? query : ''}
                              onChange={(e) => {
                                  setQuery(e.target.value)
                              }}/>
                      <div className={"absolute left-[12px] top-[12px]"}>
                        <i className="fa fa-search" aria-hidden="true"></i>
                      </div>
                        
                  </div>
<div
                          className="export-btn absolute right-0 top">
                          <ExportFile data={uniqueData} fileName={'FAQ-template-data'}/>
                      </div>
              </div>
                            

                             
              
              <CustomDataTable data={uniqueData} columns={columns} /></div>}

            
 
          
        </>
      }
    ></Layout>
  );
};

export default IdentityListPage;
