export function filterDataTable(tableData, tableData1, tableData2){
    const combinedData = tableData.concat(tableData1, tableData2);
    const sortedData = [...combinedData].sort((a, b) => b.rank - a.rank);
    return sortedData.reduce((acc, current) => {
        const x = acc.find(item => item.textId === current.textId);
        if (!x) {
            return acc.concat([current]);
        } else {
            return acc;
        }
    }, []);
}

export function filterDataTable5Col(id, tableData, tableData1, tableData2, tableData3, tableData4){
    const combinedData = tableData.concat(tableData1, tableData2,tableData3, tableData4);
    const sortedData = [...combinedData].sort((a, b) => b.rank - a.rank);
    return sortedData.reduce((acc, current) => {
        const x = acc.find(item => item[id] === current[id]);
        if (!x) {
            return acc.concat([current]);
        } else {
            return acc;
        }
    }, []);
}

export function filterDataTable4Col(id, tableData, tableData1, tableData2, tableData3){
    const combinedData = tableData.concat(tableData1, tableData2, tableData3);
    const sortedData = [...combinedData].sort((a, b) => b.rank - a.rank);
    return sortedData.reduce((acc, current) => {
        const x = acc.find(item => item[id] === current[id]);
        if (!x) {
            return acc.concat([current]);
        } else {
            return acc;
        }
    }, []);
}

export function filterDataTable3Col(id, tableData, tableData1, tableData2){
    const combinedData = tableData.concat(tableData1, tableData2);
    const sortedData = [...combinedData].sort((a, b) => b.rank - a.rank);
    return sortedData.reduce((acc, current) => {
        const x = acc.find(item => item[id] === current[id]);
        if (!x) {
            return acc.concat([current]);
        } else {
            return acc;
        }
    }, []);
}
export function filterDataTable2Col(id, tableData, tableData1){
    const combinedData = tableData.concat(tableData1);
    const sortedData = [...combinedData].sort((a, b) => b.rank - a.rank);
    return sortedData.reduce((acc, current) => {
        const x = acc.find(item => item[id] === current[id]);
        if (!x) {
            return acc.concat([current]);
        } else {
            return acc;
        }
    }, []);
}

export function removeDuplicateRow(column, data){
    return data.reduce((acc, current) => {
        const x = acc.find(item => item[column] === current[column]);
        if (!x) {
            return acc.concat([current]);
        } else {
            return acc;
        }
    }, []);
}
export function getUniqueData(column, data){
    const workerSet = new Set(data.map(item => item[column]));
    return Array.from(workerSet);  

}

function countTotalItem(data, textId, value) {
    console.log(data)
    const count= data.filter(item => { return item[textId]===value});
    console.log(count, count.length);
      console.log(textId, value);
    return count.length;
}

export function areaStatus(item) {
    return item.status === 'P' ? 'yellow' :
        item.pausedItem === item.selectedItem && item.selectedItem>0 ? 'combo':
        item.pausedItem>0 && item.selectedItem >0? 'combo-1':
        item.totalItem === item.selectedItem && item.totalItem>0? 'green':
        item.selectedItem > 0 ? 'purple': 'default';
}

export function areaCounterUpgrade(data, parrent, current, checked, childData) {
    return data.map((item) => { 
        const tItem=item.selectedItem?item.selectedItem:0;
        const nTotal= checked? tItem + 1: tItem - 1
        if(item[parrent] === current){
           
            const childItem=item.totalItem?item.totalItem:countTotalItem(childData.data, childData.textId, item[parrent]);
            
            return { ...item, selectedItem: nTotal, totalItem:childItem }
        } else {
           return item;
        } 
        
    });
}
export function sortedDataByRank(data, column) {
    const sortedData = [...data].sort((a, b) => a[column] - b[column]);
    return  sortedData  
}
