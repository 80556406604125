import { useEffect, useState } from "react";
import {Button, Form} from 'react-bootstrap';
import AddNewTemplateForNotification from "../../component/notificationSetting/AddNewTemplateForNotification";
import { useDispatch, useSelector } from "react-redux";
// import Dropdown from 'react-bootstrap/Dropdown';
import { fetchNotificationTemplateData, updateNotificationSettingColumns, deleteNotificationTemplate} from "../../reducers/NotificationSlice";

import CustomDataTable from "../../components/CustomDataTable";
import Layout from "../../components/Layout";
import ExportFile from "../../component/utility-component/ExportFile";
import { toast } from 'react-toastify';
import { truncateString } from "../../utility/StringModify";

const NotificationTemplate = () => {
  const [show, setShow] = useState(false);
  const [query, setQuery] = useState('');
  const handleShow = () => setShow(true);

  const {data, categories} = useSelector((state) => state.notification);
  const dispatch = useDispatch();
  console.log(data)
 
  const handleClose = () => {
    setShow(false)
    setInitialData(() => ({
      update: false,
      textId: null,
      categoryTitle: null,
      title: null,
      notificationEventLabel: null,
      notificationContent: null, 
      rank:0,
      status: 'Y',
      isEmail:'Y',
      isPush:'Y',
      isRealTime:'Y'
    }))};
   const [ initialData, setInitialData ] = useState({
      update: false,
      textId: null,
      categoryTitle: null,
      title: null,
      notificationEventLabel: null,
      notificationContent: null, 
      rank:0,
      status: 'Y',
      isEmail:'Y',
      isPush:'Y',
      isRealTime:'Y'
    })

  useEffect(() => {
    dispatch(fetchNotificationTemplateData());
 
    // dispatch(breadcrumbTree({'Setting': 'setting','Notification Template List':''}));
    // document.title = 'Notification Management';
    
  }, [dispatch]);

    let uniqueData = data.filter(item => item['title']?.toLowerCase().includes(query.toLowerCase()))
    // let uniqueData = filteredProvider.filter(item => item['status']?.toLowerCase().includes(filterBy.toLowerCase()))

  const columns = [
    // { name: "ID", sortable: true, cell: (row) => row.textId },
    // { name: "Notification From", sortable: true, cell: (row) => row.notificationFrom },
    // { name: "Notification To", sortable: true, cell: (row) => row.notificationTo },
    { name: "Title",  minWidth: '200px', sortable: true, cell: (row) => row.title },
    { name: "Event Label",  minWidth: '200px', sortable: true, cell: (row) => row.notificationEventLabel },
    { name: "Description ",  minWidth: '350px', sortable: true, cell: (row) => truncateString(row.notificationContent, 150) },
    { name: "Category", sortable: true, cell: (row) => row.categoryTitle },


    // { name: "Email", minWidth: '100px', sortable: true, cell: (row) => row.email},
    // { name: "Push", minWidth: '100px', sortable: true, cell: (row) => row.push},
    // { name: "Real time", minWidth: '100px', sortable: true, cell: (row) => row.realtime},
        {name: "Email",   cell: (row) => 
            <div className='item-switch'>
        <Form.Check type="switch" name='isEmail' defaultChecked={row.isEmail === 'Y'? true:false}  
        onChange={(e)=>{
             const approval = window.confirm("Are you sure about changing the notification template?");
            if (approval === true) {
            let localData = null;
            data.map((e, index)=>e.id === row.id? localData = index:''); 
            const item ={};
            item.id =  row.id;
            item.textId =  row.textId;
            item.localData = localData;
            item.name= e.target.name;
            item.value= e.target.checked?'Y':'N';
            // console.log(item)
            const res= dispatch(updateNotificationSettingColumns(item));
            res.then(results => {
                if(results.error) {toast(` Something went wrong. Please try again!`) }
            }).catch(error => {
                alert('error ---- false')
            });
          }
    }}/> 
    </div>
        },
            {name: "Push",   cell: (row) => 
            <div className='item-switch'>
        <Form.Check type="switch" name='isPush' defaultChecked={row.isPush === 'Y'? true:false}  
        onChange={(e)=>{
            const approval = window.confirm("Are you sure about changing the notification template?");
            if (approval === true) {
            let localData = null;
            data.map((e, index)=>e.id === row.id? localData = index:''); 
            const item ={};
            item.id =  row.id;
            item.textId =  row.textId;
            item.localData = localData;
            item.name= e.target.name;
            item.value= e.target.checked?'Y':'N';
            // console.log(item)
            const res= dispatch(updateNotificationSettingColumns(item));
            res.then(results => {
                if(results.error) {toast(` Something went wrong. Please try again!`) }
            }).catch(error => {
                alert('error ---- false')
            });
          }
    }}/>  
    </div>
        },
    
        {name: "In App",   cell: (row) => 
            <div className='item-switch'>
        <Form.Check type="switch" name='isRealTime' defaultChecked={row.isRealTime === 'Y'? true:false}  
        onChange={(e)=>{
            const approval = window.confirm("Are you sure about changing the notification template?");
            if (approval === true) {
            let localData = null;
            data.map((e, index)=>e.id === row.id? localData = index:''); 
            const item ={};
            item.id =  row.id;
            item.textId =  row.textId;
            item.localData = localData;
            item.name= e.target.name;
            item.value= e.target.checked?'Y':'N';
            // console.log(item)
            const res= dispatch(updateNotificationSettingColumns(item));
            res.then(results => {
                if(results.error) {toast(` Something went wrong. Please try again!`) }
            }).catch(error => {
                alert('error ---- false')
            });
          } else{
            e.target.value=row.isRealTime;
       
          }
    }
    }/>   
    </div>
        },
     {name: "Status",   cell: (row) => 
            <div className='item-switch'>
        <Form.Check type="switch" name='status' defaultChecked={row.status === 'Y'? true:false}  
        onChange={(e)=>{
            let localData = null;
            data.map((e, index)=>e.id === row.id? localData = index:''); 
            const item ={};
            item.id =  row.id;
            item.textId =  row.textId;
            item.localData = localData;
            item.name= e.target.name;
            item.value= e.target.checked?'Y':'N';
            // console.log(item)
            const res= dispatch(updateNotificationSettingColumns(item));
            res.then(results => {
                if(results.error) {toast(` Something went wrong. Please try again!`) }
            }).catch(error => {
                alert('error ---- false')
            });
    }}/>  
    </div>
        },

    // { name: "status", minWidth: '100px', sortable: true, cell: (row) => row.status},
 
   
     
    {
      name: "Action", minWidth: '120px',
      cell: (row) => (<div className="small-btn">
        <span className="btn-edit"
          onClick={() => {
            let localData = null;
            data.map((e, index)=>e.textId === row.textId? localData = index:'');
              setInitialData((initialData) => ({
              ...initialData,
              update: true,
              id: row.id, 
              // notificationFrom: row.notificationFrom,
              // notificationTo: row.notificationTo,
              textId: row.textId,
              title: row.title,
              notificationContent: row.notificationContent,
              categoryTextId: row.categoryTextId,
              status: row.status,
              localData: localData
            }));
            setShow(true)
          }} > Edit  </span>
        {/* <span className="btn-view"> view </span>  */}

        <span className="btn-delete" 
        onClick={() => {
            const approval = window.confirm("Are you sure you want to delete?");
            if (approval === true) {
              
                let updateIndex = null;
                data.map((e, index)=>{
                  if(e.textId === row.textId) {return updateIndex = index;}
                });
                const deleteRow ={};
                deleteRow.textId =  row.textId;
                deleteRow.localData =  updateIndex;
 
              dispatch(deleteNotificationTemplate(deleteRow));
            }
          }}
        > Delete </span>
          
        </div>
      ),
    },
    
     
    // {
    //   name: "Delete",
    //   cell: (row) => (
    //     <Button
    //       variant="danger"
    //       onClick={() => {
    //         const approval = window.confirm("Are you sure you want to delete?");
    //         if (approval === true) {
              
    //             let updateIndex = null;
    //             data.map((e, index)=>{
    //               if(e.textId === row.textId) {return updateIndex = index;}
    //             });
    //             const data ={};
    //             data.textId =  row.textId;
    //             data.localData =  updateIndex;
 
    //           // dispatch(deleteCity(data));
    //         }
    //       }}
    //     >
    //       Delete
    //     </Button>
    //   ),
    // },

     
  ];
  return (
    <Layout page_title={'Notification Management'} breadcrumb={{'Setting': 'setting','Notification Management': ''}}
      component={
        <> 
            {categories && show ? <AddNewTemplateForNotification data={initialData} categories={categories} close={handleClose} />:
            <div className='data-table-wrapper newNotification-table'>
               <div className="w-[100%] flex gap-2.5 relative mb-3">
                                {/*<Button variant="primary" onClick={handleShow}> Add City </Button>*/}
                                {/* <Button variant="primary" onClick={handleShow}> Add Attribute </Button> */}
                                <Button className="new-configuration-btn add-new" onClick={handleShow}> Add Item </Button>

                                <div className='search-from-data-table bg-neutral-100 rounded-lg pl-[20px] relative'>
                                    <input name="search" placeholder={'Search by Title...'}
                                           type="text"
                                           className="bg-transparent border-0 px-3 py-[10px] w-full focus:outline-none"
                                           value={query ? query : ''}
                                           onChange={(e) => {
                                               setQuery(e.target.value)
                                           }}/>
                                    <div className={"absolute left-[12px] top-[12px]"}>
                                      <i className="fa fa-search" aria-hidden="true"></i>
                                    </div>
                                     
                                </div>
<div
                                        className="export-btn absolute right-0 top">
                                        <ExportFile data={uniqueData} fileName={'Notification-template-data'}/>
                                    </div>
                            </div>
              
              <CustomDataTable data={uniqueData} columns={columns} /></div>}
        </>
      }
    ></Layout>
  );
};

export default NotificationTemplate;
