//  import { Form, Button } from "react-bootstrap";
import {Form, Modal, Button, Image} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import React, {useState, useEffect} from "react";
//  import axios from "axios";
import {BASE_URL} from "../BaseUrl";
import {createServiceCustomization, fetchServicesCustomization} from "../reducers/ServiceCustomizationSlice";
import {useFormik} from "formik";
import {createCityZip, updateCityZip} from "../reducers/CityZipSlice";
import {zipSchema} from "../schemas/zip";
import {zipCodeRejectedPost, zipCodeList} from "../reducers/TeamMemberSlice";

const ZipCodeModal = ({data, close}) => {
    const dispatch = useDispatch();


    const handleStatus = (event) => {
        const details = event.target.value;
        // console.log('details',details)
        // setEditorData(details);
        // const file = event.target.files[0];
        // setValues({...values, subject: details})
        if(values.subject){setValues({...values, status: details})}
        else {
            {setValues({...values, status: details,  message: details, subject: details })}
        }

    };

    const {values, errors, touched, setValues, handleChange, handleSubmit} = useFormik({
        initialValues: data,
        onSubmit: (values, action) => {
            const res= dispatch(zipCodeRejectedPost(values));
            // {res && close(true); dispatch(zipCodeList(values));
                
            // }
            
            res.then((results)=> { 
                console.log('results', results);
                if(!results.error){
                dispatch(zipCodeList(values));
                close(true);
                }else {

                }
            }).catch(error => {
                console.error('Error:', error);
            });
        },
        // validationSchema: zipSchema,
        // handleChange: (values, action) => {
        //     console.log(values)
        // },
        // handleBlur: (values, action) => {
        //     console.log('handleBlur')
        // }

    });
    // console.log("🚀 ~ file: CityZipForm.jsx:21 ~ CityZipForm ~ data:", values)

    return (
        <>
            <Form id="emailSent" className="custom-modal" onSubmit={handleSubmit}>
                <div className="custom-dialog close-modal" onClick={close}></div>

                <div className="modal-content">

                    <div className="modal-header"><h2>Do you want to change zip code status?</h2>
                        <div className="close-modal" onClick={close}>&times;</div>
                    </div>
                    <div className="modal-body">

                        <div
                            className="zip-list d-flex justify-content-between align-items-center bg-war rounded-8 px-2 py-2 mb-3">
                            <div className='address'>
                                <img src="/svg/location.svg" alt=""/> 
                                {data.zip && <span>{data.zip} </span>}  
                                {data.city && <span>, {data.city} </span>} 
                                {data.state && <span>, {data.state} </span>}
                                {data.state && <span>, {data.state} </span>}
                                {data.country && <span>, {data.country} </span>}
                                   
                            </div>
                        </div>
                        <div className="why">
                            <h4 className="pb-2">Explain why ?</h4>
                            <div className="mb-3">
                                <Form.Select aria-label="Default select example" className="form-control" name="status"
                                             value={values.status}  onChange={(e)=>{handleStatus(e); }}>
                                    <option selected disabled defaultValue={data.status}>{data.status}</option>
                                    <option value='Pending'>Pending</option>
                                    <option value='Approved'>Approved</option>
                                    <option value='Rejected'>Rejected</option>
                                    )</Form.Select>
                            </div>
                            <div className="mb-3">
                                <input type="text" className="form-control" name='subject' id="subject" value={values.subject} onChange={handleChange}  placeholder="Subject"/>
                            </div>
                            <div className="mb-3">
                              <textarea className="form-control" name='message' id="message" rows="3" value={values.message} onChange={handleChange}
                                        placeholder={values.message}></textarea>
                            </div>


                        </div>
                    </div>
                    <div className="modal-footer">


                        <Button type="submit" className="btn btn-success rounded-pill px-4"> Confirm </Button>
                        &nbsp; &nbsp;
                        <button className="btn bg-white rounded-pill border-red px-4" onClick={close}>Cancel </button>
                    </div>
                </div>
            </Form>
        </>
    );
};


export default ZipCodeModal;

