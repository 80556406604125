import React, {useState} from 'react';
import {Form} from "react-bootstrap";
import {workerDataValidationNoticePost} from "../../reducers/TeamMemberSlice";
import {useFormik} from "formik";
// import {createCityZip, updateCityZip} from "../../reducers/CityZipSlice";
// import {zipSchema} from "../../schemas/zip";
import {useDispatch} from "react-redux";

const RemarksComponent = ({sectionId, profile, handleButtonClick, defaultStatus, nextBtn}) => {
    const [next, setNext] = useState(false)
    const [active, setActive] = useState(false)
    const dispatch = useDispatch();
    const handleNext = () => setNext(!next);
    const handleSwitch = (e) => setActive(e.target.checked?true:false);

     

        // console.log(profile);

    const {values, handleChange, handleSubmit, setValues} = useFormik({
        initialValues: {status: profile.status, workerTextId: profile.textId, workerStage: defaultStatus},
        
        onSubmit: (values, action) => {
           const res= dispatch(workerDataValidationNoticePost(values));
           res.then((results)=>{
                if(results.error){}else{
                    handleNext()
                    setActive(false)
                }
           })
            // console.log(values);
        },
        // validationSchema: zipSchema,
    });
    //   console.log(profile.email);
    // console.log(values);
    return (
        <>
        {profile.status === 'Processing' &&
        <>
                {nextBtn && <button className="btn btn-success rounded-8 save-next" onClick={handleButtonClick}> Save and
                    Next </button>}

                <Form.Check type="switch" checked={active&&true} id={sectionId}
                            label="If you have any issues do you want to notify the worker?"  
                            onChange={(e)=>{
                              return  handleSwitch(e)
                                 }}/>

                {active &&
                    <div className="content bg-white rounded-12 mt-4 remark-section">
                        <div className='title'>Remarks</div>
                        <Form onSubmit={handleSubmit}>
                        <Form.Select aria-label="Default select example" className="form-control" name="workerStage"
                                     value={values.workerStage} onChange={()=>{
                                         console.log('workerTextId: ',profile.textId)
                                                setValues({...values, status: profile.status, workerTextId: profile.textId})
                                            return handleChange

                                     }}>
                            <option selected disabled defaultValue={defaultStatus}>{defaultStatus}</option>
                            <option value='Personal Information'>Personal Information</option>
                            <option value="Mailing Address Required">Mailing Address Required</option>
                            <option value='PhotoId Required'>PhotoId Required</option>
                            <option value='SSN Required'>SSN Required</option>
                            <option value='Selfie Required'>Selfie Required</option>
                            <option value="Processing">Processing</option>
                            {/*<option value="Verified">Verified</option>*/}
                            {/*<option value="Rejected">Rejected</option>*/}
                        </Form.Select>

                        <Form.Control type="text" name="subject" placeholder="Subject" value={values.title}
                                      onChange={handleChange}></Form.Control>
                        <textarea className="form-control description" name="message" placeholder='Your Message'
                                  value={values.itemDescription}
                                  onChange={handleChange} rows="4" cols="50"></textarea>

                        <button className="btn btn-success rounded-8 save-notification" type='submit'>Sent Remarks</button>
                    </Form>
                    </div>}
            </>
        }
        </>
    );
}

export default RemarksComponent;