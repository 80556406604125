import { useEffect, useState } from "react";
import {Button, Image, Form} from 'react-bootstrap';
import AddCategoryForNotification from "../../component/notificationSetting/AddCategoryForNotification";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from 'react-bootstrap/Dropdown';
import {fetchNotificationCategoriyList, updateNotificationCategory} from "../../reducers/NotificationSlice";

import CustomDataTable from "../../components/CustomDataTable";
import Layout from "../../components/Layout";
import ExportFile from "../../component/utility-component/ExportFile";
import { toast } from 'react-toastify';
import {sortedDataByRank} from "../../utility/FilterDataTable";
const NotificationCategories = () => {
 
  const [show, setShow] = useState(false);
  const [query, setQuery] = useState('');
  const handleShow = () => setShow(true);
  
 

  const {categories} = useSelector((state) => state.notification);
  const dispatch = useDispatch();
 
 
  const handleClose = () => {
    setShow(false)
    setInitialData(() => ({
            update: false, 
            textId: null,
            title: null,
            rank: 0,
            status: 'Y',
            }))};
   const [ initialData, setInitialData ] = useState({
      update: false, 
      textId: null, 
      title: null, 
      rank:0,
      status: 'Y',
    })

  useEffect(() => {
  
    dispatch(fetchNotificationCategoriyList());
    // dispatch(breadcrumbTree({'Setting': 'setting','Category List':'category'}));
    document.title = 'Add Category For Notification';
    
  }, []);

    const sortedData  = categories.filter(item => item['title']?.toLowerCase().includes(query.toLowerCase()))
    // let uniqueData = filteredProvider.filter(item => item['status']?.toLowerCase().includes(filterBy.toLowerCase()))
    let uniqueData= sortedDataByRank(sortedData);
 
  const columns = [
 
    { name: "Id", sortable: true, cell: (row) => row.id },
    { name: "Title", sortable: true, cell: (row) => row.title },
    // { name: "Text Id", sortable: true, cell: (row) => row.textId },
    { name: "Rank ", sortable: true, cell: (row) => row.rank }, 

    // { name: "status", minWidth: '100px', sortable: true, cell: (row) => row.status}, 
     {name: "Status", cell: (row) => 
            <div className='item-switch'>
        <Form.Check type="switch" name='status' defaultChecked={row.status === 'Y'? true:false}  
        onChange={(e)=>{
          
            let localData = null;
            categories.map((e, index)=>e.id === row.id? localData = index:''); 
            const item ={};
            item.id =  row.id;
            item.textId =  row.textId;
            item.title =  row.title;
            item.rank =  row.rank;
            item.localData = localData;
            item.status= e.target.checked?'Y':'N';
            // console.log(item)
            const res= dispatch(updateNotificationCategory(item));
            res.then(results => {
                if(results.error) {toast(` Something went wrong. Please try again!`) }
            }).catch(error => {
                alert('error ---- false')
            });
    }}/>  
    </div>
        },

    // { name: "status", minWidth: '100px', sortable: true, cell: (row) => row.status},
 
   
     
    {
      name: "Action", minWidth: '80px',
      cell: (row) => (<div className="small-btn"> 
    <button className="btn-edit edit"
          onClick={() => {
            let localData = null;
            categories.map((e, index)=>e.textId === row.textId? localData = index:'');
              setInitialData((initialData) => ({
              ...initialData,
              update: true,
              id: row.id, 
              textId: row.textId,
              title: row.title,
              status: row.status,
              rank: row.rank,
              localData: localData
            }));
            setShow(true)
          }} > Edit  </button>
 

       
          
        </div>
      ),
    },
    
    

     
  ];
  return (
    <Layout page_title={' Category List For Notification'} breadcrumb={{'Notification Categories': ''}} 
      component={
        <> 
            {categories && show ? <AddCategoryForNotification data={initialData} categories={categories} close={handleClose} />:
            <div className='data-table-wrapper newNotification-table'>
               <div className="w-[100%] flex gap-2.5 relative mb-3">
 
                                <Button className="new-configuration-btn add-new" onClick={handleShow}> Add Item </Button>

                                <div className='search-from-data-table bg-neutral-100 rounded-lg pl-[20px] relative'>
                                    <input name="search" placeholder={'Search by Title...'}
                                           type="text"
                                           className="bg-transparent border-0 px-3 py-[10px] w-full focus:outline-none"
                                           value={query ? query : ''}
                                           onChange={(e) => {
                                               setQuery(e.target.value)
                                           }}/>
                                    <div className={"absolute left-[12px] top-[12px]"}>
                                      <i className="fa fa-search" aria-hidden="true"></i>
                                    </div>
                                     
                                </div>
<div
                                        className="export-btn absolute right-0 top">
                                        <ExportFile data={uniqueData} fileName={'Notification-template-data'}/>
                                    </div>
                            </div>
              
              <CustomDataTable data={uniqueData} columns={columns} /></div>}
        </>
      }
    ></Layout>
  );
};

export default NotificationCategories;
