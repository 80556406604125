import React, {useEffect, useRef, useState} from 'react';
import RemarksComponent from "./RemarksComponent";
import {useLocation} from "react-router-dom";
import {changeTeamMemberStatus} from "../../reducers/TeamMemberSlice";
import {useDispatch} from "react-redux"; 
import {successConfirmation} from "../../reducers/CategorySlice";
import LoadingComponent from "../../modal/LoadingComponent";
import ErrorComponent from "../../modal/ErrorComponent";
import SuccessComponent from "../../modal/SuccessComponent";
const SetProviderStatus = ({profile, loading, error, }) => {
    const [active,setActive]= useState(false)
    const [statusToggle,setStatusToggle]= useState(false)
    const dispatch = useDispatch()
    const [successModal, setSuccessModal] = useState(false);
    const handleSuccess = ()=>dispatch(successConfirmation());
    const openSuccessModal = (url) => setSuccessModal(url ? url : false);
    const closeSuccessModal = (url) => { setSuccessModal(false) };
    const handleToggle = () => setStatusToggle(statusToggle? false:true);
    const handleSubmit =()=>{
    const res = dispatch(changeTeamMemberStatus({ textId: profile.textId, status: active}))
    res.then((results)=> {
        handleToggle()
        openSuccessModal(true);
    }).catch(error => {
        console.error('Error:', error);
    });
}


    return (
        <>
            <div className="provider-status">
                <input type="checkbox" id="statusToggle" onClick={handleToggle} checked={statusToggle}/>
                <label className="status-hamburger bg-white rounded-8 px-2 py-2"
                       htmlFor="statusToggle"><i
                    className="fa-solid fa-ellipsis-vertical"></i></label>

                <div id="statusCard" className="status-card relative">
                    <span className='remove cursor-pointer' onClick={()=>handleToggle()}><i className="fa fa-times" aria-hidden="true"></i></span>
                    <div className="status-msg cursor-pointer" onClick={()=>handleToggle()}>
                        Set Provider Status
                    </div>
                    <label htmlFor="approved" className='cursor-pointer'>
                        <input type="radio" id="approved" name="status" value="Approved" checked={active==='Approved'} onClick={(e)=>setActive(e.target.value)}/>
                        Approved
                    </label>
                    <label htmlFor="busy" className='cursor-pointer'>
                        <input type="radio" id="busy" name="status" value="Busy" checked={active==='Busy'} onClick={(e)=>setActive(e.target.value)}/>
                        Busy
                    </label>
                    {/* <label htmlFor="temporary">
                        <input type="radio" id="temporary" name="status" value="Un" checked={active==='Temporary'} onClick={(e)=>setActive(e.target.value)}/>
                        Temporary
                    </label> */}
                    <label htmlFor="temporaryBlock" className='cursor-pointer'>
                        <input type="radio" id="temporaryBlock" name="status" value="TemporaryBlock" checked={active==='TemporaryBlock'} onClick={(e)=>setActive(e.target.value)}/>
                        Temporary Block
                    </label>
                    <label htmlFor="declined" className='cursor-pointer'>
                        <input type="radio" id="declined" name="status" value="Declined" checked={active==='Declined'} onClick={(e)=>setActive(e.target.value)}/>
                        Declined
                    </label>
                    <button className="status-save-btn w-100" onClick={handleSubmit}>Status Save</button>
                </div>
            </div>
            {loading && <LoadingComponent message={''}/>}
            {error && <ErrorComponent close={handleSuccess} error={error}/>}
            {successModal && <SuccessComponent close={closeSuccessModal}/>}
        </>
    );
}

export default SetProviderStatus;