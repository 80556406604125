import * as Yup from "yup";

export const faqSchema = Yup.object({
  // textId: Yup.string().min(3).max(25).required("Please enter TextId"),
  question: Yup.string().max(255).required("Please enter FAQ Title"),
  answer: Yup.string().min(3).required("Please enter FAQ Answer"),
  faqCategory: Yup.string().min(3).max(50).required("Please select a FAQ Category"),
  faqDestination: Yup.string().min(3).max(50).required("Please select a FAQ Destination"),
});

export const moduleRolePermissionSchema = Yup.object({ 
  roleTextId: Yup.string().required("Select a Module Role Permission"), 
});