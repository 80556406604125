import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
// import CustomDataTable from '../../components/CustomDataTable'
// import {fetchProfile, changeTeamMemberStatus} from '../../reducers/TeamMemberSlice'
// import {Button, Form} from 'react-bootstrap'
import Layout from '../../components/Layout'
import { useParams} from "react-router-dom";
// import CustomModelComponent from "../../component/CustomModelComponent";
// import CustomModal from "../../modal/CustomModal";
// import SuccessModalComponent from "../../modal/SuccessModalComponent";
// import HeaderComponent from "../../component/provider/HeaderComponent";

// import GeneralInfo from "../../component/provider/GeneralInfo";
// import UserOrders from "../../component/provider/ProviderOrders";
// import CategoryRequested from "../../component/provider/CategoryRequested";
// import BusinessInfo from "../../component/provider/BusinessInfo";
import {fetchOrderDetails} from "../../reducers/OrderSlice";
// import {item} from
// import {BASE_URL} from "../../BaseUrl";
import OrderDetailsComponent from "../../component/order/OrderDetailsComponent";

import LoadingComponent from "../../modal/LoadingComponent";  
import ErrorComponent from "../../modal/ErrorComponent";

const OrderDetailsPage = () => {
    // const [activeTab, setActiveTab] = useState('profile')
 
    const {order_details, loading, error} = useSelector((state) => state.order)
    const dispatch = useDispatch()
    const {orderTextId, user} = useParams()
    // const handleClick = (e) => setActiveTab(e);
    // const handleNext = (e) => setActiveTab('category');


    const workerMap = {};

// console.log(user_data)
    const order_data = order_details.order_data;
    // const user_data = order_details.user_data;
    const workerData = order_details.workerData;
    // const payment_summary = order_details.payment_summary;
    // const paymentArray = order_details.paymentArray;


        const dateObj = new Date(order_data && order_data.created);
        const formattedDate = dateObj.toLocaleString("en-US", {
            weekday: 'short', // Thu
            year: 'numeric', // 2024
            month: 'long', // April
            day: 'numeric', // 01
            hour: 'numeric', // 2 (24-hour format)
            minute: 'numeric', // 51
            hour12: true, // 2 (12-hour format)
            // minute: '2-digit',
            timeZone: 'UTC' // Z (Coordinated Universal Time)
        });
    if (workerData) {
        workerData.map((item) =>  workerMap[item.workerTextId] = item)
    }
    // console.log(order_details)


    useEffect(() => {
        // dispatch(breadcrumbTree({}));
        // dispatch(fetchProfile(item))
        const res = dispatch(fetchOrderDetails(orderTextId))
        res.then((results)=>{
            // const res2=dispatch(fetchOrderTracking(orderTextId))
        } )

        res.catch((error) => {
            console.error('Promise rejected:', error);
        });
        // dispatch(breadcrumbTree(user ? {'Customer':'customer',' Order ': 'order', ' Order Details': ''}:{' Order ': 'order', ' Order Details': ''}));
        document.title = user?'Customer Order Listing':'Provider Order Listing';
    }, [dispatch, orderTextId, user])

    // console.log(profile.photoIdExpirationDate)
    // console.log('workerData',order_details)
    return (
        <Layout page_title={'Order Details'} breadcrumb={{'Customer':'customer',' Order ': 'order', ' Order Details': ''}}
            component={
                <>
                    {/* <div className="section-two-column order-details"> */}
                        {order_details && <OrderDetailsComponent order_details={order_details} />}
                    {/* </div> */}
                        {loading && <LoadingComponent message={''}/>}
                        {error && <ErrorComponent error={error} redirectLink={'/order'}/>}
                </>
            } 
            top_content={<>

                <div className=" justify-start items-start gap-3 inline-flex">
                    <div className="flex-col justify-start items-start gap-1 inline-flex">
                        <div className="text-black text-xl font-semibold font-['Inter']">Order Number#
                            {order_data && <span> {order_data.textId}</span>}
                        </div>
                        <div className="justify-start items-center gap-2 inline-flex">
                            <div className="w-5 h-5 relative"><i className="fa-solid fa-calendar"></i></div>
                            <div className="text-neutral-600 text-base font-normal font-['Inter']">Placed on
                                <span> {order_data && formattedDate}</span>
                            </div>
                        </div>
                    </div>
                    {/*<div className="px-2 py-[3px] bg-emerald-700 rounded justify-center items-center gap-1.5 flex">*/}
                    {/*    <div className="w-1.5 h-1.5 bg-white rounded-sm"></div>*/}
                    {/*    <div*/}
                    {/*        className="text-white text-xs font-medium font-['Inter'] leading-[18px] tracking-tight">Paid*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
               

            </>}></Layout>
    );
}

export default OrderDetailsPage;