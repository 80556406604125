import React, { useState} from 'react';
// import React, {Component, useEffect} from 'react';
// import AreasListComponent from "./AreasListComponent";
// 
import { useSelector} from "react-redux";
// import {useDispatch} from "react-redux";
// import {Link, useParams} from "react-router-dom";
import {BASE_URL} from "../../BaseUrl";
import CategoriesModal from "../../modal/CategoriesModal";


const CategoryCardComponent = ({data}) => {
    const { loading} = useSelector((state) => state.teamMembers)
    // const dispatch = useDispatch()
    // const {item} = useParams()
    const [model,setModel]= useState(false);
    const handleClose =()=>setModel(false)
console.log('CategoryCardComponent:',data)
    return (
        <>
            <div className="list d-flex gap-3 align-items-center px-3 py-3 rounded-8 border border-2 relative cursor-pointer" onClick={() => setModel(true)}>
                <span className='status-icon absolute right-3 pending'>Pending</span>
                <div className='canvas'>
                    <img src={`${BASE_URL}${data.categoryIcon}`} alt={data.categoryTitle}/>
                    {/*<img src="../img/houseClean.png" alt=""/>*/}
                </div>
                <div className='title'>{data.categoryTitle}</div>
            </div>
            {model && <CategoriesModal data={data} close={handleClose} loading={loading}/>}
        </>
    );

}

export default CategoryCardComponent;